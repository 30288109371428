import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUser } from "../../Apis/configs/axiosConfig";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import {
  Avatar,
  Card,
  CircularProgress,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import "./dashboard.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { SpinnerCircular } from "spinners-react";
import { useMediaQuery } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from 'react-i18next';
import {
  ArrowBack,
  Close,
  EnergySavingsLeaf,
  InvertColorsOff,
  NotificationsOff,
  Pause,
  PlayCircle,
  PowerSettingsNewRounded,
  SkipNext,
  Square,
  Warning,
  WaterDrop,
} from "@mui/icons-material";
import {
  amber,
  blueGrey,
  cyan,
  green,
  grey,
  red,
  yellow,
} from "@mui/material/colors";

const styleRadioButton = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  borderRadius: 6,
  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
  padding: 1,
  color: "black",
  fontSize: 16,
  textAlign: "center",
  maxHeight: "50%",
  overflow: "auto",
};

const LITER_TO_GALLONS = 0.264172;

export const Dashboard = ({ user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const [programs, setPrograms] = useState([]);
  const [address, setAddress] = useState(null);
  const [weatherData, setWeatherData] = useState();
  const [powerState, setPowerState] = useState();
  const [isPowerStateReady, setPowerStateReady] = useState(true);
  const [isCancelReady, setCancelReady] = useState(true);
  const [open, setOpen] = useState(false);
  const [radioButton, setRadioButton] = useState([]);
  const [programRunning, setProgramRunning] = useState(null);
  const [zones, setZones] = useState([]);
  const [mapOption, setmapOption] = useState([]);
  const [totalZones, setTotalZones] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [radioZone, setradioZone] = useState([]);
  const [openTimer, setOpenTimer] = useState(false);
  const [dashData, setDashData] = useState("");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [runningZones, setRunningZones] = useState(dashData || []);
  const [allZones, setAllZones] = useState([]);
  const [advice, setAdvice] = useState(false);
  const [runningLoading, setRunningloading] = useState(false);
  const [realtimeDynamicData, setRealtimeDynamicData] = useState({
    fm_1: 0,
    fm_2: 0,
    line_1: 0,
    line_2: 0,
  });
  const [isWaitingRuntime, setIsWaitingRuntime] = useState(false);

  const [form, setForm] = useState(
    address || {
      alias: location.state.alias,
      address: {
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
    }
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    try {
      fetchCurrentStatus();
      fetchRealTimeData();
    } catch (e) {
      console.error(e);
    }

    const timer = setInterval(() => {
      fetchCurrentStatus();
      fetchRealTimeData();
    }, 10 * 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (dashData) {
      fetchProgramRunning();
      fetchDynamicData();
    }
  }, [dashData]);

  useEffect(() => {
    if (address !== null && !weatherData) {
      fetchWeatherData();
      if (!advice && !weatherData) {
        const timer = setTimeout(() => {
          show_Advice();
          setAdvice(true);
        }, 5500); 
  
        return () => clearTimeout(timer);
      }
    }
  }, [address, weatherData, advice]);
 

  useEffect(() => {
    if (!isPowerStateReady) {
      setTimeout(() => {
        setPowerStateReady(true);
      }, 30 * 1000);
    }
  }, [isPowerStateReady]);

  useEffect(() => {
    if (!isCancelReady) {
      setTimeout(() => {
        setCancelReady(true);
      }, 30 * 1000);
    }
  }, [isCancelReady]);
  
  const changeDevicePowerMode = async () => {
    const isOn = powerState === "AUTO";

    Swal.fire({
      title: "Power Status Change",
      text: isOn
        ? "Want to turn OFF the device?"
        : "Want to turn ON the device?",
      icon: "question",
      confirmButtonText: isOn ? "Yes, turn it OFF" : "Yes, turn it ON",
      confirmButtonColor: isOn ? red[900] : green[900],
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          setPowerStateReady(false);
          setPowerState(isOn ? "OFF" : "AUTO");
          apiUser.put(`/controllers/${location.state.id}/update_power`, {
            auto: isOn ? false : true,
          });
        } catch (e) {
          console.error(e);
        }
      }
    });
  };

  const fetchCurrentStatus = async () => {
    try {
      const currentStatusResponse = await apiUser.get(
        `/controllers/${location.state.id}`
      );

      // program data
      setPrograms(Object.keys(currentStatusResponse.data.data.programs));

      // zone data
      let connectedZones = currentStatusResponse.data.data.zones.filter(
        (item) => item.decoder !== "000000"
      );
      setAllZones(currentStatusResponse.data.data.zones);
      setZones(connectedZones);
      setTotalZones(connectedZones.length);

      // address
      setAddress(currentStatusResponse.data.data.address);
      // power status data

     
      const newPowerState = currentStatusResponse.data.data.status;

      if (isPowerStateReady) {
        setPowerState(newPowerState);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const fetchRealTimeData = async () => {
    const resp = await apiUser.get(
      `/controllers/${location.state.id}/realtime`
    );
    setDashData(resp.data.data);

    if (resp.data.data.irrigation.zones) {
      const updatedRunningZones = resp.data.data.irrigation.zones.map(
        (zone) => {
          const foundIndex = allZones.findIndex(
            (allZone) => allZone.decoder === zone.zone_data.decoder
          );
          return foundIndex !== -1
            ? { ...zone.zone_data, index: foundIndex }
            : { ...zone, index: null };
        }
      );

      if (updatedRunningZones.length > 0) {
        setIsWaitingRuntime(false);
      }
      setRunningZones(updatedRunningZones);
    } else {
      // console.log("Error");
    }
  };

  const fetchProgramRunning = async () => {
    if (dashData.irrigation && dashData.irrigation.zones.length > 0) {
      const isRunning = dashData.irrigation.zones[0].program;
      if (
        isRunning === "A" ||
        isRunning === "B" ||
        isRunning === "C" ||
        isRunning === "D" ||
        isRunning === "F" ||
        isRunning === "E"
      ) {
        setProgramRunning(isRunning);

        if (isRunning) {
          setIsWaitingRuntime(false);
        }
      } else {
        setProgramRunning("Z");
      }
    } else {
      setProgramRunning(null);
    }
  };

  const fetchDynamicData = async () => {
    if (dashData.real_time) {
      setRealtimeDynamicData({ ...dashData.real_time });
    }
  };

  const fetchWeatherData = async () => {
    try {
      const weatherResponse = await apiUser.get(
        `/controllers/${location.state.id}/weather`,
        {
          params: {
            lt: address.latitude,
            ln: address.longitude,
          },
        }
      );

      setWeatherData(weatherResponse.data);
    } catch (err) {
  

     
    }
  };

  const handleChange = (event) => {
    setRadioButton(event.target.value);
  };

  const handleChangeZones = (event) => {
    setradioZone(event.target.value);
    setOpenTimer(true);
  };

  const handleClearFault = () => {
    Swal.fire({
      title: t("Clear Fault"),
      text: t("Command sent to controller"),
      icon: "success",
      timer: 1500,
      confirmButtonColor: "#032C65",
      confirmButtonText: t("Ok"),
      background: "#f7f8fa",
    }).then((result) => {
      if (result.isConfirmed) {
        apiUser
          .put(`/controllers/${location.state.id}/clear_fault`, {
            clear: true,
          })
          .catch((err) => console.error(err));
      }
    });
  };

  const handleCloseTimer = () => setOpenTimer(false);

  const handleManualAdvance = () => {
    setRunningloading(true);
    apiUser
      .put(
        `/controllers/${location.state.id}/update_schedule/manual_operation`,
        {
          advance: 1,
        }
      )
      .then((val) => {
        Swal.fire({
          title: t("Manual Advance"),
          text: t("Command sent to controller"),
          icon: "success",
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#032C65",
        }).then((val) => setRunningloading(false));
      })
      .catch((err) => {
        console.error(err);

        setRunningloading(false);
      });
  };

  const handleManualRunCancel = () => {
    Swal.fire({
      title: "Modify Irrigation",
      text: "Please confirm to cancel manual irrigation",
      icon: "question",
      confirmButtonText: "Cancel Irrigation",
      confirmButtonColor: "#032C65",
    }).then((result) => {
      if (result.isConfirmed) {
        setCancelReady(false);
        setRunningloading(true);
        apiUser
          .put(
            `/controllers/${location.state.id}/update_schedule/manual_operation`,
            {
              cancel: 0,
            }
          )
          .then((val) => {
            setRunningloading(false);
          })
          .catch((err) => {
            console.error(err);

            setRunningloading(false);
          });
      }
    });
  };

  const handleOpen = (action) => {
    setOpen(true);

    switch (action) {
      case "start_program":
        setmapOption(1);
        break;
      case "start_zone":
        setmapOption(2);
        break;
      case 3:
        setmapOption(3);
        break;

      default:
    }
  };

  const handleClose = () => setOpen(false);

  const handleHours = (e) => {
    // console.log(e.target.value);
    setHours(e.target.value);
  };

  const handleMinute = (e) => {
    // console.log(e.target.value);
    setMinutes(e.target.value);
  };

  const Confirm_request = async () => {
    const totalSeconds = hours * 3600 + minutes * 60;

    let body = {};

    if (mapOption === 2) {
      body = {
        station: Number(radioZone) + 1,
        time: totalSeconds,
      };
    } else if (mapOption === 1) {
      // console.log(radioButton);
      body = {
        program: radioButton,
      };
    } else if (mapOption === 3) {
      return saveEdit();
    }

    try {
      setLoading(true);
      const resp = await apiUser.put(
        `/controllers/${location.state.id}/update_schedule/manual_operation`,
        body
      );
      setIsWaitingRuntime(true);

      setTimeout(() => {
        setIsWaitingRuntime(false);
      }, 60 * 1000);

      // console.log(resp);
    } catch (e) {
      console.error(e);
    }
    handleClose();
    setLoading(false);
  };
  const show_Advice = () => {
    Swal.fire({
      title: t("ADVICE"),
      html:
        t("To use the following features, you need to configure your address:")+ "<br/><br/>" +
       " -" + t("Map")+ "<br/>" +
        "-" +  t("Weather station") + "<br/><br/>" +
        t("Please go to configure the address on the main page of the controllers."),
      icon: "info",
      confirmButtonColor: "#032C65",
      confirmButtonText: t("Confirm"),
    }).then((data) => {
      if (data.isConfirmed) {
        setmapOption(3);
        handleOpen(3);
      }
    });
  };

  const saveEdit = async () => {
    const body = {
      alias: location.state.alias,
      address: {
        zipcode: form.address.zipcode,
        country: form.address.country,
        city: form.address.city,
        address_line_1: form.address.address_line_1,
        address_line_2: form.address.address_line_2,
        state: form.address.state,
      },
    };

    try {
      setLoading(true);

      const resp = await apiUser.put(
        `/controllers/${location.state.id}/update_data/`,
        body
      );
      // console.log(resp.data);
      if (resp.data.success) {
        setLoading(false);
        handleClose();

        Swal.fire({
          title: t("Edit Controller"),
          text: t("Controller has been modified"),
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  const hasRunningZones = runningZones && runningZones.length > 0;

  return (
    <>
      <Grid item container xs={12} justifyContent="center">
        <Grid
          item
          container
          component={Paper}
          elevation={0}
          xs={11}
          sx={{
            border: `1px solid ${grey[300]}`,
            backgroundColor: "#F7F8FA",
            paddingY: 2,
            paddingX: 4,
            justifyContent: { xs: "start", md: "space-between" },
            marginX: { xs: 2, sm: 0, md: 0, lg: 0 },
          }}
          borderRadius={6}
        >
          <Grid
            sx={{ borderRadius: "8px" }}
            item
            xs={12}
            sm={2}
            md={1}
            alignItems="start"
            component={Stack}
            justifyContent={{
              xs: "start",
              sm: "start",
              md: "center",
              lg: "center",
            }}
          >
            <IconButton
              size="large"
              sx={{ backgroundColor: "white" }}
              onClick={() => navigate("/mycontrollers")}
            >
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            alignItems="center"
            component={Stack}
            sx={{
              borderRadius: "8px",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "start",
                lg: "start",
              },
              justifyContent: { xs: "center", md: "start" },
            }}
          >
            <Typography variant="h5" fontWeight="bolder" color="#6B7A99">
              {location.state && location.state.alias
                ? location.state.alias
                : ""}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={10}
            md={4}
            lg={3}
            component={Paper}
            elevation={0}
            paddingX={3}
            paddingY={0.2}
            textAlign="left"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ borderRadius: "13px" }}
            sx={{
              marginTop: { xs: 0, sm: 1, md: 0, lg: 0 },
              color: programRunning ? "white" : grey[800],
              backgroundColor: programRunning ? green[700] : grey[200],
            }}
          >
            <Grid item>
              <Typography variant="body" component="div">
                {t('Status')}:
              </Typography>
              <Typography fontWeight="bolder">
                {programRunning
                  ? programRunning === "Z"
                    ? t("Manually Running")
                    :t( `Program`) +` ${programRunning}` + t(`Running`)
                  : t("Not watering")}
              </Typography>
            </Grid>
            <Grid item>
              {programRunning ? (
                <WaterDrop
                  fontSize="large"
                  sx={{
                    color: green[200],
                  }}
                />
              ) : (
                <InvertColorsOff
                  fontSize="large"
                  sx={{
                    color: grey[500],
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          sx={{
            marginX: { xs: 2, sm: 0, md: 0, lg: 0 },
          }}
        >
          {loading ? (
            <Grid item xs={5}>
              <SpinnerCircular />
            </Grid>
          ) : (
            <Grid item container xs={12} justifyContent="space-evenly">
              <Grid
                item
                container
                xs={11}
                marginTop={2}
                justifyContent="space-evenly"
                sx={{
                  width: "100%",
                  borderRadius: 6,
                  paddingY: 2,
                  paddingX: 1,
                  backgroundColor: "white",
                }}
              >
                <Grid
                  container
                  spacing={0}
                  item
                  justifyContent="space-between"
                  sx={{ paddingX: 3 }}
                >
                  <Grid
                    item
                    container
                    sm={3.8}
                    component={Card}
                    elevation={4}
                    sx={{
                      borderRadius: 6,
                      marginTop: { xs: 2, sm: 3, md: 0 },
                    }}
                    padding={1}
                  >
                    <Grid
                      item
                      container
                      xs={isSmallScreen ? 3 : 5}
                      sm={4}
                      justifyContent="center"
                      alignContent="center"
                      padding={2}
                      textAlign="left"
                    >
                      <CircularProgressbarWithChildren
                        value={(totalZones / 99) * 100}
                        strokeWidth={10}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor: "green",
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={7}
                      sm={8}
                      justifyContent="start"
                      alignContent="center"
                      textAlign="left"
                    >
                      <Grid item container xs={12} justifyContent="center">
                        <Typography variant="caption" display="block">
                          {t('Connected Zones')}
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} justifyContent="center">
                        <Typography
                          variant="h4"
                          display="block"
                          fontWeight="bolder"
                        >
                          {totalZones} / 99
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={3.8}
                    elevation={4}
                    sx={{
                      borderRadius: 6,
                      marginTop: { xs: 2, sm: 3, md: 0 },
                    }}
                    justifyContent="center"
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      sm={10}
                      component={Paper}
                      elevation={4}
                      onClick={() => {
                        if (isPowerStateReady) {
                          changeDevicePowerMode();
                        }
                      }}
                      padding={1}
                      justifyContent="center"
                      alignContent="center"
                      marginTop={{ xs: 0.5, sm: 0, md: 0, lg: 0 }}
                      marginBottom={{ xs: 0, sm: 0, md: 1, lg: 1 }}
                      borderRadius={6}
                      sx={{
                        backgroundColor:
                          powerState === t("AUTO") ? green[100] : red[50],
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={4}
                        sx={{
                          justifyContent: {
                            xs: "end",
                            sm: "center",
                            md: "end",
                            lg: "end",
                          },
                        }}
                      >
                        {isPowerStateReady ? (
                          <PowerSettingsNewRounded
                            fontSize="large"
                            color={powerState === "AUTO" ? "success" : "error"}
                          />
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                      <Grid
                        item
                        container
                        xs={8}
                        sm={12}
                        md={8}
                        justifyContent="center"
                        alignContent="center"
                      >
                        <Typography
                          variant="body1"
                          fontWeight="bolder"
                          display="block"
                        >
                          {t(powerState)}
                        </Typography>
                      </Grid>
                      {/* {!isPowerStateReady && (
                    <Grid container justifyContent="center">
                      <Box sx={{ width: "80%" }}>
                        <LinearProgress />
                      </Box>
                    </Grid>
                  )} */}
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={10}
                      component={Paper}
                      elevation={4}
                      onClick={() => {
                        handleClearFault();
                      }}
                      padding={1}
                      justifyContent="center"
                      alignContent="center"
                      marginTop={{ xs: 0.5, sm: 1, md: 0, lg: 0 }}
                      borderRadius={6}
                    >
                      <Grid
                        item
                        container
                        xs={4}
                        sx={{
                          justifyContent: {
                            xs: "end",
                            sm: "center",
                            md: "end",
                            lg: "end",
                          },
                        }}
                      >
                        <NotificationsOff fontSize="large" color="warning" />
                      </Grid>
                      <Grid
                        item
                        container
                        xs={8}
                        sm={12}
                        md={8}
                        justifyContent="center"
                        alignContent="center"
                      >
                        <Typography
                          variant="body1"
                          fontWeight="bolder"
                          display="block"
                          textAlign="center"
                        >
                          {t('Clear Fault')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={3.8}
                    component={Card}
                    elevation={4}
                    sx={{
                      backgroundColor:
                        weatherData && !weatherData.success
                          ? red[50]
                          : blueGrey[50],
                      borderRadius: 6,
                      marginTop: { xs: 2, sm: 3, md: 0 },
                    }}
                    padding={1}
                  >
                    {address === null ? (
                      <Grid item xs={12} alignSelf="center">
                        <Typography
                          variant="caption"
                          display="block"
                          textTransform="capitalize"
                        >
                          {t(`Need address to load weather`)}
                        </Typography>
                      </Grid>
                    ) : !weatherData ? (
                      <Grid item xs={12} alignSelf="center">
                        <SpinnerCircular size="80" />
                      </Grid>
                    ) : weatherData.success ? (
                      <>
                        <Grid
                          item
                          container
                          xs={5}
                          sm={4}
                          justifyContent="center"
                          alignContent="center"
                          padding={1}
                          textAlign="left"
                        >
                          <img
                            src={`/weather_icons/${weatherData.icon}`}
                            alt="weather_icon"
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              padding: 0,
                              margin: 0,
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          xs={7}
                          sm={8}
                          justifyContent="start"
                          alignContent="center"
                          textAlign="left"
                        >
                          <Grid item container xs={12}>
                            <Typography
                              display="block"
                              variant="body"
                              textTransform="capitalize"
                              textAlign="center"
                            >
                              {`${
                                weatherData.location_name
                              }, ${weatherData.location_country.toUpperCase()}`}
                            </Typography>
                          </Grid>
                          {weatherData.success && (
                            <Grid item container xs={12}>
                              <Typography
                                variant="h4"
                                display="block"
                                fontWeight="bolder"
                              >
                                {`${parseInt(weatherData.avg_temp)} ${
                                  weatherData.uom
                                }`}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent="center"
                        alignContent="center"
                      >
                        <Warning
                          color="error"
                          titleAccess={t("Something went wrong while loading weather")}
                          sx={{ fontSize: "3em" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={0}
                  justifyContent="space-between"
                  sx={{
                    marginTop: { xs: 1, sm: 2, md: 3, lg: 3 },
                    paddingX: 3,
                  }}
                >
                  <Grid
                    item
                    container
                    xs={5.7}
                    sm={4.7}
                    md={2.7}
                    paddingX={3}
                    paddingY={1}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ borderRadius: "14px" }}
                    sx={{
                      marginTop: { xs: 0, sm: 1, md: 0, lg: 0 },
                      backgroundColor:
                        realtimeDynamicData.line_1 > 20
                          ? amber[100]
                          : green[100],
                    }}
                  >
                    <Grid item xs={5}>
                      <EnergySavingsLeaf
                        sx={{
                          color:
                            realtimeDynamicData.line_1 > 20
                              ? amber[700]
                              : green[600],
                          fontSize: "3.2em",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7} textAlign="start">
                      <Typography
                        variant="body"
                        component="div"
                        color="#9B715D"
                      >
                        {t('Line 1')}
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight="900"
                        component="div"
                        color={blueGrey[800]}
                        sx={{ display: { xs: "none", md: "block" } }}
                      >
                        {t('Output current')}
                      </Typography>
                      <Typography
                        fontWeight="900"
                        fontSize="1.2em"
                        component="div"
                        color="#9B715D"
                      >
                        {realtimeDynamicData.line_1} {t(`mA`)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5.7}
                    sm={4.7}
                    md={2.7}
                    paddingX={3}
                    paddingY={1}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ borderRadius: "14px" }}
                    sx={{
                      marginTop: { xs: 0, sm: 1, md: 0, lg: 0 },
                      backgroundColor:
                        realtimeDynamicData.line_2 > 20
                          ? amber[100]
                          : green[100],
                    }}
                  >
                    <Grid item xs={5}>
                      <EnergySavingsLeaf
                        sx={{
                          color:
                            realtimeDynamicData.line_2 > 20
                              ? amber[700]
                              : green[600],
                          fontSize: "3.2em",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7} textAlign="start">
                      <Typography
                        variant="body"
                        component="div"
                        color="#9B715D"
                      >
                        {t('Line 2')}
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight="900"
                        component="div"
                        color={blueGrey[800]}
                        sx={{ display: { xs: "none", md: "block" } }}
                      >
                        {t('Output current')}
                      </Typography>
                      <Typography
                        fontWeight="900"
                        fontSize="1.2em"
                        component="div"
                        color="#9B715D"
                      >
                        {realtimeDynamicData.line_2} mA
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5.7}
                    sm={4.7}
                    md={2.9}
                    paddingX={{ xs: 1, sm: 2, md: 3, lg: 3 }}
                    paddingY={1}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ borderRadius: "14px" }}
                    sx={{
                      marginTop: { xs: 1, sm: 1, md: 0, lg: 0 },
                      backgroundColor: green[50],
                    }}
                  >
                    <Grid item xs={12} sm={5}>
                      <WaterDrop
                        sx={{
                          color: cyan[300],
                          fontSize: "3.2em",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={7}
                      paddingLeft={2}
                      textAlign="start"
                    >
                      <Typography
                        variant="body"
                        component="div"
                        color={blueGrey[800]}
                      >
                        {t('Meter 1')}
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight="900"
                        color={green[700]}
                        sx={{ display: { xs: "none", sm: "block" } }}
                      >
                        {t('Live Flow')}
                      </Typography>
                      <Tooltip title="Gallons Per Minute">
                        <Typography fontWeight="900" color={green[900]}>
                          {realtimeDynamicData.fm_1 > 0
                            ? (
                                realtimeDynamicData.fm_1 / LITER_TO_GALLONS
                              ).toFixed(2)
                            : 0}
                          &nbsp;GPM
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5.7}
                    sm={4.7}
                    md={2.9}
                    paddingX={{ xs: 1, sm: 2, md: 3, lg: 3 }}
                    paddingY={1}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ borderRadius: "14px" }}
                    sx={{
                      marginTop: { xs: 1, sm: 1, md: 0, lg: 0 },
                      backgroundColor: green[50],
                    }}
                  >
                    <Grid item xs={12} sm={5}>
                      <WaterDrop
                        sx={{
                          color: cyan[300],
                          fontSize: "3.2em",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={7}
                      paddingLeft={2}
                      textAlign="start"
                    >
                      <Typography
                        variant="body"
                        component="div"
                        color={blueGrey[800]}
                      >
                        {t('Meter 2')}
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight="900"
                        component="div"
                        color={green[700]}
                        sx={{ display: { xs: "none", sm: "block" } }}
                      >
                       {t( 'Live Flow')}
                      </Typography>
                      <Tooltip title="Gallons Per Minute">
                        <Typography
                          fontWeight="900"
                          component="div"
                          color={green[900]}
                        >
                          {realtimeDynamicData.fm_2 > 0
                            ? (
                                realtimeDynamicData.fm_2 / LITER_TO_GALLONS
                              ).toFixed(2)
                            : 0}
                          &nbsp;GPM
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={11}
                marginTop={2}
                paddingX={4}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  container
                  xs={5}
                  sm={2.9}
                  component={Paper}
                  elevation={4}
                  onClick={() => handleOpen("start_zone")}
                  padding={1}
                  justifyContent="center"
                  alignContent="center"
                  marginTop={{ xs: 0.5, sm: 0, md: 0, lg: 0 }}
                  borderRadius={6}
                >
                  <Grid
                    item
                    container
                    xs={4}
                    alignContent="center"
                    sx={{
                      justifyContent: {
                        xs: "end",
                        sm: "center",
                        md: "end",
                        lg: "end",
                      },
                    }}
                  >
                    {isWaitingRuntime ? (
                      <CircularProgress />
                    ) : (
                      <PlayCircle fontSize="large" color="primary" />
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={8}
                    sm={12}
                    md={8}
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bolder"
                      display="block"
                    >
                      {t('Start Zone')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={5}
                  sm={2.9}
                  component={Paper}
                  elevation={4}
                  onClick={() => handleOpen("start_program")}
                  padding={1}
                  justifyContent="center"
                  alignContent="center"
                  marginTop={{ xs: 0.5, sm: 0, md: 0, lg: 0 }}
                  borderRadius={6}
                >
                  <Grid
                    item
                    container
                    xs={4}
                    alignContent="center"
                    justifyContent={{
                      xs: "end",
                      sm: "center",
                      md: "end",
                      lg: "end",
                    }}
                  >
                    {isWaitingRuntime ? (
                      <CircularProgress />
                    ) : (
                      <PlayCircle fontSize="large" color="primary" />
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={8}
                    sm={12}
                    md={8}
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bolder"
                      display="block"
                      textAlign="center"
                    >
                      {t('Start Program')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={5} sm={2.9} justifyContent="center">
                  <Grid
                    item
                    container
                    component={Paper}
                    elevation={4}
                    onClick={() => handleManualAdvance()}
                    padding={1}
                    justifyContent="center"
                    alignContent="center"
                    borderRadius={6}
                    marginTop={{ xs: 0.5, sm: 0, md: 0, lg: 0 }}
                  >
                    <Grid
                      item
                      container
                      xs={4}
                      alignContent="center"
                      justifyContent={{
                        xs: "end",
                        sm: "center",
                        md: "end",
                        lg: "end",
                      }}
                    >
                      {runningLoading ? (
                        <CircularProgress />
                      ) : (
                        <SkipNext fontSize="large" color="error" />
                      )}
                    </Grid>
                    <Grid
                      item
                      container
                      xs={8}
                      sm={12}
                      md={8}
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bolder"
                        display="block"
                        textAlign="center"
                      >
                        {t('Manual Advance')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {hasRunningZones && (
                  <Grid item container xs={5} sm={2.9} justifyContent="center">
                    <Grid
                      item
                      container
                      component={Paper}
                      elevation={4}
                      onClick={() => handleManualRunCancel()}
                      padding={1}
                      justifyContent="center"
                      alignContent="center"
                      borderRadius={6}
                      marginTop={{ xs: 0.5, sm: 0, md: 0, lg: 0 }}
                    >
                      <Grid
                        item
                        xs={4}
                        sx={{
                          justifyContent: {
                            xs: "end",
                            sm: "center",
                            md: "end",
                            lg: "end",
                          },
                        }}
                      >
                        {runningLoading ? (
                          <CircularProgress />
                        ) : isCancelReady ? (
                          <Square fontSize="large" color="error" />
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sm={12}
                        md={8}
                        justifyContent="center"
                        alignContent="center"
                      >
                        <Typography
                          variant="body1"
                          fontWeight="bolder"
                          display="block"
                          textAlign="center"
                        >
                          {t('Cancel')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {hasRunningZones ? (
                <Grid
                  item
                  container
                  xs={11}
                  component={Paper}
                  elevation={0}
                  marginTop={5}
                  padding={2}
                  borderRadius={6}
                  justifyContent={{ xs: "center", md: "start" }}
                >
                  <Grid item xs={12} justifyContent="start" marginBottom={2}>
                    <Typography
                      variant="h6"
                      textAlign="left"
                      fontWeight="bolder"
                    >
                      {t('Running Zones')}
                    </Typography>
                  </Grid>
                  {runningZones.map((zone, index) => (
                    <Grid
                      key={`zone-running-${index}`}
                      item
                      container
                      xs={10}
                      sm={4.3}
                      md={3.5}
                      lg={2.4}
                      component={Card}
                      elevation={4}
                      margin={1}
                      paddingX={3}
                      paddingY={1}
                      borderRadius={6}
                    >
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignContent="center"
                        padding={1}
                        textAlign="left"
                        xs={4}
                        md={5}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "#14b549",
                            width: 30,
                            height: 30,
                          }}
                        >
                          <Typography variant="caption" fontWeight="bolder">
                            {zone ? Number(zone.index) + 1 : ""}
                          </Typography>
                        </Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="start"
                        alignContent="center"
                        textAlign="left"
                        xs={8}
                        md={7}
                      >
                        <Grid item container xs={12}>
                          <Typography variant="body" fontWeight="bolder">
                            {zone ? zone.zone_name : ""}
                          </Typography>
                        </Grid>
                        <Grid item container xs={12}>
                          <Typography variant="caption">
                            Zone{" "}
                            {zone.index ? Number(zone.index) + 1 : index + 1}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  xs={5}
                  justifyContent="center"
                  marginTop={5}
                  padding={2}
                  spacing={2}
                  borderRadius={6}
                  sx={{ backgroundColor: "white" }}
                >
                  <Typography variant="h5" color={grey[500]}>
                    {t('No Zones Running')}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Modals : Start Programs & zones */}
      <Modal open={open} onClose={handleClose}>
        <Grid
          item
          container
          xs={12}
          sx={styleRadioButton}
          justifyContent="center"
        >
          <Grid container justifyContent="end" padding={1}>
            <Close
              size="large"
              color="primary"
              sx={{ right: 5, cursor: "pointer" }}
              onClick={handleClose}
            />
          </Grid>
          <Grid item container xs={10}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              {mapOption === 1 && t("Start Program")}
              {mapOption === 2 && t("Start Zones")}
              {mapOption === 3 && t("Set Address")}
            </Typography>
          </Grid>
          <Grid item container xs={10} paddingY={3} justifyContent="center">
            {mapOption === 3 && address && (
              <>
                <label
                  style={{
                    display: "block",

                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`Address line 1`)}:
                </label>
                <textarea
                  placeholder={
                    address.address_line_1 === ""
                      ? t("Enter a valid address")
                      : address.address_line_1
                  }
                  onChange={(e) =>
                    setForm({
                      ...form,
                      address: {
                        ...form.address,
                        address_line_1: e.target.value,
                      },
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",

                    border: "1px solid #ccc",
                    textAlign: "center",
                  }}
                />
                <label
                  style={{
                    display: "block",

                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`Address line 2`)}:
                </label>
                <textarea
                  placeholder={
                    address.address_line_2 === ""
                      ? t("Enter a valid address")
                      : address.address_line_2
                  }
                  onChange={(e) =>
                    setForm({
                      ...form,
                      address: {
                        ...form.address,
                        address_line_2: e.target.value,
                      },
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",

                    border: "1px solid #ccc",
                    textAlign: "center",
                  }}
                ></textarea>
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`City`)}:
                </label>
                <textarea
                  placeholder={address.city === "" ? t("City") : address.city}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      address: { ...form.address, city: e.target.value },
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                  }}
                ></textarea>
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`State`)}:
                </label>
                <textarea
                  placeholder={address.state === "" ? t("State") : address.state}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      address: { ...form.address, state: e.target.value },
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                  }}
                ></textarea>
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`Zipcode`)}:
                </label>
                <textarea
                  placeholder={
                    address.zipcode === ""
                      ? t("Enter a zipcode")
                      : address.zipcode
                  }
                  onChange={(e) =>
                    setForm({
                      ...form,
                      address: { ...form.address, zipcode: e.target.value },
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",

                    border: "1px solid #ccc",
                    textAlign: "center",
                  }}
                ></textarea>
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`Country`)} :
                </label>
                <textarea
                  placeholder={
                    address.country === "" ? t("Country") : address.country
                  }
                  onChange={(e) =>
                    setForm({
                      ...form,
                      address: { ...form.address, country: e.target.value },
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                  }}
                ></textarea>
              </>
            )}
            {mapOption !== 3 && (
              <>
                <InputLabel id="modal-select">
                  {mapOption === 1 && t("Select Program")}
                  {mapOption === 2 && t("Select Zones")}
                </InputLabel>
                <Select
                  id="modal-select"
                  onChange={mapOption === 1 ? handleChange : handleChangeZones}
                  sx={{ width: "100%" }}
                >
                  {mapOption === 1
                    ? programs.map((program) => (
                        <MenuItem
                          key={`${program}`}
                          value={program}
                        >{t(`Program`) + ` ${program.toUpperCase()}`}</MenuItem>
                      ))
                    : zones.map((zone, index) => (
                        <MenuItem key={`z-${index}`} value={index}>
                          {zone.zone_name
                            ? zone.zone_name
                            : t(`Zone`) + ` ${index + 1}`}
                        </MenuItem>
                      ))}
                </Select>
              </>
            )}

            <Grid item xs={12} md={8} paddingY={3}>
              <LoadingButton
                variant="contained"
                loadingPosition="center"
                loading={loading}
                sx={{
                  backgroundColor: "#032C65",
                  color: "white",
                  marginBottom: 2,
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#032C65",
                  },
                }}
                onClick={() => Confirm_request()}
              >
                {t(`Confirm`)}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      {/* Modals : Run time zone */}
      <Modal
        open={openTimer}
        onClose={handleCloseTimer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleRadioButton}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            marginBottom={4}
          >
            {t(`Manual Run Duration`)}
          </Typography>
          <label
            style={{ color: "#032C65", fontWeight: "normal", fontSize: "16px" }}
          >
            {t(`Hours`)} :{" "}
          </label>
          <select
            id="hour"
            name="hour"
            value={hours}
            style={{
              width: "20%",
              height: "40px",
              textAlign: "center",
              borderRadius: "5px",
              marginRight: "10px",
            }}
            onChange={handleHours}
          >
            {Array.from({ length: 10 }, (_, i) => i).map((hours) => (
              <option key={hours} value={hours}>
                {hours}
              </option>
            ))}
          </select>
          <label
            style={{ color: "#032C65", fontWeight: "normal", fontSize: "16px" }}
          >
            {t(`Minutes`)} :{" "}
          </label>{" "}
          <select
            id="minutes"
            name="minutes"
            value={minutes}
            style={{
              width: "20%",
              height: "40px",
              textAlign: "center",
              borderRadius: "5px",
            }}
            onChange={handleMinute}
          >
            {Array.from({ length: 60 }, (_, i) => i).map((minutes) => (
              <option key={minutes} value={minutes}>
                {minutes}
              </option>
            ))}
          </select>
          <Button
            sx={{
              width: "50%",
              height: "10%",
              fontSize: "14px",
              marginTop: "30px",
              backgroundColor: "#032C65",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
              color: "white",
              fontWeight: "normal",
              marginBottom: 4,
              "&:hover": {
                backgroundColor: "white",
                color: "#032C65",
              },
            }}
            onClick={handleCloseTimer}
          >
            {t(`Confirm`)}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
